<template>
	<div>
		<v-row class="mt-2">
			<v-col md="12">
				<h1 class="mt-2 mb-6">Gold Card</h1>
			</v-col>
			<v-col md="4">
				<v-autocomplete v-model="organizzatoCorrente" :items="listaOrganizzati" label="Organizzato" item-text="DescOrganizzato2" item-value="CodOrganizzato" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" @change="fetchData" clearable>
				</v-autocomplete>
			</v-col>

			<v-col md="4">
				<v-autocomplete v-model="annoCorrente" clearable label="Anno" :items="listaAnni" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" @change="fetchData"></v-autocomplete>
			</v-col>
			<v-col md="4">
				<v-autocomplete v-model="meseCorrente" clearable :disabled="!annoCorrente" label="Mese" item-value="key" item-text="value" :items="listaMesi" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" @change="fetchData"></v-autocomplete>
			</v-col>
			<v-col md="6"></v-col>
			<v-col md="6">
				<v-text-field v-model="tableSearch" append-icon="mdi-magnify" label="Ricerca libera" hide-details clearable :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-text-field>
			</v-col>
		</v-row>
		<v-row class="mt-5">
			<v-col>
				<v-data-table 
					class="reportTable"
					item-class="caption"
					:headers="tableHeaders"
					:items="reportData"
					:search="tableSearch"
					:loading="loading"
					loading-text="Caricamento..."
					:footer-props="{ itemsPerPageOptions: [10,25,100,250,500,-1]}"
				>
					<template v-slot:[`item.attivazione`]="{ item }">
						<span>{{ item.attivazione | reportShortDate }}</span>
					</template>				
				</v-data-table>
			</v-col>
		</v-row>
		<v-row>
			<v-col sm="6">
				<json-excel :data="reportData" name="report-goldcard.xls" class="" style="cursor: pointer;"><v-icon>mdi-microsoft-excel</v-icon> excel download</json-excel>
			</v-col>
		</v-row>

		<debug-panel label="" class="mt-6">
			{{  }}
		</debug-panel>
	</div>
</template>

<script>
/**********************************************************************************************
**** REPORT CARD MESE ****************************************************************************
***********************************************************************************************
* 
*/
import moment from 'moment'
import lodash from 'lodash'
import DebugPanel from '@/components/debug-panel'
import JsonExcel from 'vue-json-excel'
//
export default {
	name: 'ReportCardMese',
	components: { DebugPanel, JsonExcel },
	props: {

	},
	data: () => {
		return {
			loading: false,
			listaOrganizzati: [],
			organizzatoCorrente: null,
			annoCorrente: null,
			meseCorrente: null,
			tableSearch: '',
			tableHeaders: [
				{ text: 'Cod. Org.' 	, value: 'orgattivazione' 	},
				{ text: 'Data Attivazione'	, value: 'attivazione' 		},
				{ text: 'Card ID' 	, value: 'idcard' 			,},
				{ text: 'Telaio' 		, value: 'codtelaio' 				,},
				{ text: 'Codice Fiscale' 		, value: 'codfiscale' 				,},
				{ text: 'Cognome' 		, value: 'cognome' 				, },
				{ text: 'Nome' 		, value: 'nome' 				, },
			],
			reportData: [],
			errors: [],
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		darkTheme() { return this.$vuetify.theme.dark },
		currentUser() { return this.$store.getters['currentUser'] },
		isAdmin() { return this.currentUser.IsAdmin || this.currentUser.IsSuperuser},
		cellularOpenMode() { return this.$store.getters['cellularOpenMode'] },
		// lista degli anni dal 2009 ad oggi
		listaAnni() { return lodash.range((new Date()).getFullYear(), 2009, -1)	},
		// lista dei mesi
		listaMesi() { 
			var mesi = []
			let nomi = moment.months() 
			for (let index = 1; index <= 12; index++) {
				mesi.push({ key: index, value: nomi[index-1] })
			}
			return mesi
		},
	},
	created: function () {
		this.fetchOrganizzati()
		this.fetchData()
	},
	methods: {
		fetchOrganizzati() {
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/report/organizzatiAttivi'}).then(result => {
				this.listaOrganizzati = result
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		// carica gli organizzati 
		fetchData() {
			this.reportData = []
			this.loading = true
			let req = {
				CodiceOrganizzato: this.organizzatoCorrente,
				Anno: this.annoCorrente,
				Mese: this.meseCorrente,
			}
			this.$store.dispatch('genericApiPost', {apiUrl: '/report/goldCardMese', value: req}).then(result => {
				this.reportData = result
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		decodificaMese(idMese){
			if(this.listaMesi && this.listaMesi.length>0 && idMese>=1 && idMese <=12)
				return this.listaMesi[idMese - 1].value
			else
				return ''
		}		
	},
}
</script>

<style scoped lang="less">

</style>