<template>
	<standard-container menu-id="report">
		<!-- header -->
		<template v-slot:header>
			<v-tabs v-model="currentTab" :show-arrows="true" background-color="transparent">
				<v-tab v-if="$isAuthorizedTo('report.card.attive.pers')" key="tabCardAttivePers">Card attive</v-tab>
				<!-- <v-tab v-if="$isAuthorizedTo('report.riepilogocampagne')" key="tabRiepilogocampagne">Campagne</v-tab> -->
				<v-tab v-if="$isAuthorizedTo('report.cardorganizzato')" key="tabCardOrganizzato">Card organizzato</v-tab>
				<v-tab v-if="$isAuthorizedTo('report.annoimmatricolazione')" key="tabAnnoImmatricolazione">Anno immatricolazione</v-tab>
				<v-tab v-if="$isAuthorizedTo('report.organizzatiaderenti')" key="tabOrganizzatiaderenti">Aderenti</v-tab>
				<v-tab v-if="$isAuthorizedTo('report.cardmese')" key="tabCardmese">Card mese</v-tab>
				<v-tab v-if="$isAuthorizedTo('report.nuoveauto')" key="tabNuoveauto">Nuove auto</v-tab>
				<v-tab v-if="$isAuthorizedTo('report.conoscenzaclienti')" key="tabConoscenzacliente">Conoscenza cliente</v-tab>
				<v-tab v-if="$isAuthorizedTo('report.fattureinsospeso')" key="tabFattureinsospeso">Fatture in sospeso</v-tab>
				<v-tab v-if="$isAuthorizedTo('report.campagneamministrazione')" key="tabCampagneamministrazione">Amministrazione</v-tab>
				<v-tab v-if="$isAuthorizedTo('report.goldcard')" key="tabGoldcard">Gold Card</v-tab>
			</v-tabs>		
		</template>

		<!-- body slot -->
		<v-tabs-items v-model="currentTab">
			<!-- card attive pers -->
			<v-tab-item v-if="$isAuthorizedTo('report.card.attive.pers')" key="tabCardAttivePers" eager class="ma-1">
				<report-card-attive-pers></report-card-attive-pers>
			</v-tab-item>
			<!-- Riepilogo campagne -->
			<!-- 
			<v-tab-item v-if="$isAuthorizedTo('report.riepilogocampagne')" key="tabRiepilogocampagne" eager class="ma-1">
				<report-riepilogo-campagne></report-riepilogo-campagne>
			</v-tab-item>
			-->
			<!-- card organizzato -->
			<v-tab-item v-if="$isAuthorizedTo('report.cardorganizzato')" key="tabCardOrganizzato" eager class="ma-1">
				<report-cardorganizzato></report-cardorganizzato>
			</v-tab-item>
			<!-- anno immatricolazione -->
			<v-tab-item v-if="$isAuthorizedTo('report.annoimmatricolazione')" key="tabAnnoImmatricolazione" eager class="ma-1">
				<report-annoimmatricolazione></report-annoimmatricolazione>
			</v-tab-item>
			<!-- organizzati aderenti -->
			<v-tab-item v-if="$isAuthorizedTo('report.organizzatiaderenti')" key="tabOrganizzatiaderenti" eager class="ma-1">
				<report-organizzatiaderenti></report-organizzatiaderenti>
			</v-tab-item>
			<!--card emse -->
			<v-tab-item v-if="$isAuthorizedTo('report.cardmese')" key="tabCardmese" eager class="ma-1">
				<report-cardmese></report-cardmese>
			</v-tab-item>
			<!--nuove auto -->
			<v-tab-item v-if="$isAuthorizedTo('report.nuoveauto')" key="tabNuoveauto" eager class="ma-1">
				<report-nuoveauto></report-nuoveauto>
			</v-tab-item>
			<!-- conoscenza clienti -->
			<v-tab-item v-if="$isAuthorizedTo('report.conoscenzaclienti')" key="tabConoscenzacliente" eager class="ma-1">
				<report-conoscenzacliente></report-conoscenzacliente>
			</v-tab-item>
			<!-- fatture in sospeso -->
			<v-tab-item v-if="$isAuthorizedTo('report.fattureinsospeso')" key="tabFattureinsospeso" eager class="ma-1">
				<report-fattureinsospeso></report-fattureinsospeso>
			</v-tab-item>
			<!--campagne amministrazione -->
			<v-tab-item v-if="$isAuthorizedTo('report.campagneamministrazione')" key="tabCampagneamministrazione" eager class="ma-1">
				<report-campagneamministrazione></report-campagneamministrazione>
			</v-tab-item>
			<!-- report.goldcard -->
			<v-tab-item v-if="$isAuthorizedTo('report.goldcard')" key="tabgoldcard" eager class="ma-1">
				<report-goldcard></report-goldcard>
			</v-tab-item>
		</v-tabs-items>
	</standard-container>
</template>

<script>
/**********************************************************************************************
**** Report ***********************************************************************************
***********************************************************************************************
* 
*/
import StandardContainer from '@/components/standard-container'
import ReportCardAttivePers from '@/views/Report/report-card-attive-pers'
// import ReportRiepilogoCampagne from '@/views/Report/report-riepilogocampagne'
import ReportCardorganizzato from '@/views/Report/report-cardorganizzato'
import ReportAnnoimmatricolazione from '@/views/Report/report-annoimmatricolazione'
import ReportOrganizzatiaderenti from '@/views/Report/report-organizzatiaderenti'
import ReportCardmese from '@/views/Report/report-cardmese'
import ReportNuoveauto from '@/views/Report/report-nuoveauto'
import ReportConoscenzacliente from '@/views/Report/report-conoscenzacliente'
import ReportFattureinsospeso from '@/views/Report/report-fattureinsospeso'
import ReportCampagneamministrazione from '@/views/Report/report-campagneamministrazione'
import ReportGoldcard from '@/views/Report/report-goldcard'
export default {
	name: 'ReportPage',
	components: { 
		StandardContainer, ReportCardAttivePers, ReportCardorganizzato, // ReportRiepilogoCampagne, 
		ReportAnnoimmatricolazione, ReportOrganizzatiaderenti, ReportCardmese, ReportNuoveauto, ReportConoscenzacliente,
		ReportFattureinsospeso, ReportCampagneamministrazione, ReportGoldcard,
	},
	data: () => {
		return {
			currentTab: -1,
		}
	},
	computed: {
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
	},
	mounted() {
	},
	methods: {

	},
}
</script>

<style scoped lang="less">

</style>