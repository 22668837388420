var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"mt-2"},[_c('v-col',{attrs:{"md":"12"}},[_c('h1',{staticClass:"mt-2 mb-6"},[_vm._v("Numero card attivate per organizzato per anno di immatricolazione")])]),_c('v-col',{attrs:{"md":"6"}},[_c('v-autocomplete',{attrs:{"clearable":"","items":_vm.listaAnni,"label":"Anno","filled":_vm.tce.filled,"outlined":_vm.tce.outlined,"solo":_vm.tce.solo,"dense":_vm.tce.dense},on:{"change":_vm.fetchData},model:{value:(_vm.annoCorrente),callback:function ($$v) {_vm.annoCorrente=$$v},expression:"annoCorrente"}})],1),_c('v-col',{attrs:{"md":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Ricerca libera","hide-details":"","clearable":"","filled":_vm.tce.filled,"outlined":_vm.tce.outlined,"solo":_vm.tce.solo,"dense":_vm.tce.dense},model:{value:(_vm.tableSearch),callback:function ($$v) {_vm.tableSearch=$$v},expression:"tableSearch"}})],1)],1),_c('v-row',{staticClass:"mt-5"},[_c('v-col',[_c('v-data-table',{staticClass:"reportTable",attrs:{"item-class":"caption","headers":_vm.tableHeaders,"items":_vm.reportData,"search":_vm.tableSearch,"loading":_vm.loading,"loading-text":"Caricamento...","footer-props":{ itemsPerPageOptions: [10,25,100,250,500,-1]}},scopedSlots:_vm._u([{key:"item.attivazione",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm._f("reportShortDate")(item.attivazione)))])]}},{key:"item.cessazione",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap red--text"},[_vm._v(_vm._s(_vm._f("reportShortDate")(item.cessazione)))])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [(item.email)?_c('v-btn',{staticStyle:{"margin-left":"0","padding-left":"0"},attrs:{"text":"","x-small":"","href":'mailto:'+item.email},on:{"click":function($event){$event.stopPropagation();return _vm.nop.apply(null, arguments)}}},[_vm._v(_vm._s(item.email))]):_vm._e()]}},{key:"item.telmobile",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticStyle:{"margin-left":"0","padding-left":"0"},attrs:{"disabled":!item.telmobile,"text":"","x-small":"","target":_vm.cellularOpenMode ? '_blank' : '',"href":(_vm.cellularOpenMode ? 'https://api.whatsapp.com/send?phone=' : 'tel:') + item.telmobile},on:{"click":function($event){$event.stopPropagation();return _vm.nop.apply(null, arguments)}}},[_vm._v(_vm._s(item.telmobile))])]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"sm":"6"}},[_c('json-excel',{staticStyle:{"cursor":"pointer"},attrs:{"data":_vm.reportData,"name":"report-cardorganizzato.xls"}},[_c('v-icon',[_vm._v("mdi-microsoft-excel")]),_vm._v(" excel download")],1)],1),_c('v-col',{attrs:{"sm":"6"}},[(!_vm.loading)?_c('v-card',{attrs:{"color":"tableheader"}},[_c('v-card-title',{staticClass:"text-subtitle-2"},[_vm._v("Totale immatricolazioni "+_vm._s(_vm.annoCorrente))]),_c('v-card-text',{staticClass:"text-subtitle-1 text-right"},[_vm._v(_vm._s(_vm.totaleImmatricolazioni))])],1):_vm._e()],1)],1),_c('debug-panel',{staticClass:"mt-6",attrs:{"label":"reportData"}},[_vm._v(" "+_vm._s(_vm.reportData)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }