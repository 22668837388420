<template>
	<div>
		<v-row class="mt-2">
			<v-col md="12">
				<h1 class="mt-2 mb-6">Numero card attivate per organizzato per anno di immatricolazione</h1>
			</v-col>
			<v-col md="6">
				<v-autocomplete v-model="annoCorrente" clearable :items="listaAnni" label="Anno" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" @change="fetchData">
				</v-autocomplete>
			</v-col>
			<v-col md="6">
				<v-text-field v-model="tableSearch" append-icon="mdi-magnify" label="Ricerca libera"  hide-details clearable :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-text-field>
			</v-col>
		</v-row>
		<v-row class="mt-5">
			<v-col>
				<v-data-table 
					class="reportTable"
					item-class="caption"
					:headers="tableHeaders"
					:items="reportData"
					:search="tableSearch"
					:loading="loading"
					loading-text="Caricamento..."
					:footer-props="{ itemsPerPageOptions: [10,25,100,250,500,-1]}"
				>
					<template v-slot:[`item.attivazione`]="{ item }">
						<span class="text-no-wrap">{{ item.attivazione | reportShortDate }}</span>
					</template>
					<template v-slot:[`item.cessazione`]="{ item }">
						<span class="text-no-wrap red--text">{{ item.cessazione | reportShortDate }}</span>
					</template>
					<template v-slot:[`item.email`]="{ item }">
						<v-btn v-if="item.email" text x-small :href="'mailto:'+item.email" style="margin-left:0;padding-left:0" @click.stop="nop">{{ item.email }}</v-btn>
					</template>
					<template v-slot:[`item.telmobile`]="{ item }">
						<v-btn :disabled="!item.telmobile" text x-small :target="cellularOpenMode ? '_blank' : ''" :href="(cellularOpenMode ? 'https://api.whatsapp.com/send?phone=' : 'tel:') + item.telmobile" style="margin-left:0;padding-left:0" @click.stop="nop">{{ item.telmobile }}</v-btn>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<v-row>
			<v-col sm="6">
				<json-excel :data="reportData" name="report-cardorganizzato.xls" class="" style="cursor: pointer;"><v-icon>mdi-microsoft-excel</v-icon> excel download</json-excel>
			</v-col>
			<v-col sm="6">
				<v-card v-if="!loading" color="tableheader">
					<v-card-title class="text-subtitle-2">Totale immatricolazioni {{ annoCorrente }}</v-card-title>
					<v-card-text class="text-subtitle-1 text-right">{{ totaleImmatricolazioni }}</v-card-text>
				</v-card>
			</v-col>
		</v-row>

		<debug-panel label="reportData" class="mt-6">
			{{ reportData }}
		</debug-panel>
	</div>
</template>

<script>
/**********************************************************************************************
**** REPORT ANNI IMMATRICOLAZIONE ****************************************************************************
***********************************************************************************************
* 
*/
import DebugPanel from '@/components/debug-panel'
import JsonExcel from 'vue-json-excel'
//
export default {
	name: 'ReportCardOrganizzato',
	components: { DebugPanel, JsonExcel },
	props: {

	},
	data: () => {
		return {
			loading: false,
			listaAnni: [], 
			annoCorrente: null,
			tableSearch: '',
			tableHeaders: [
				{ text: 'Cod. Org.' 	, value: 'codorganizzato' 	},
				{ text: 'Organizzato'	, value: 'organizzato' 		},
				{ text: 'Anno Imm.' 	, value: 'annoimm' 			, align: 'end'},
				{ text: 'Parziale' 		, value: 'qty' 				, align: 'end'},

			],
			reportData: [],
			errors: [],
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		darkTheme() { return this.$vuetify.theme.dark },
		currentUser() { return this.$store.getters['currentUser'] },
		isAdmin() { return this.currentUser.IsAdmin || this.currentUser.IsSuperuser},
		cellularOpenMode() { return this.$store.getters['cellularOpenMode'] },
		totaleImmatricolazioni() {
			var result = 0
			this.reportData.forEach( x => { result += x.qty })
			return result
		}
	},
	created: function () {
		this.fetchAnni()
		this.fetchData()
	},
	methods: {
		// carica gli organizzati 
		fetchAnni() {
			this.listaAnni = []
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/report/listaAnniImmatricolazione'}).then(result => {
				this.listaAnni = result
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		fetchData() {
			this.reportData = []
			this.loading = true
			let req = {
				Anno: this.annoCorrente
			}
			this.$store.dispatch('genericApiPost', {apiUrl: '/report/annoImmatricolazione', value: req}).then(result => {
				this.reportData = result
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		nop(){

		}		
	},
}
</script>

<style scoped lang="less">

</style>