<template>
	<div>
		<v-row class="mt-2">
			<v-col>
				<h1 class="mt-2 mb-6">Riepilogo fatture per l'amministrazione</h1>
			</v-col>
		</v-row>
		<v-row class="mt-5">
			<v-col md="6">
				<v-autocomplete v-model="promozioneCorrente" return-object persistent-hint :hint="`${promozioneCorrente.Descrizione ? promozioneCorrente.Descrizione : 'Seleziona una promozione...'}`" :items="campagneAttive" label="Campagna" item-text="Titolo" item-value="IdPromozione" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" @change="fetchLotti"></v-autocomplete>
			</v-col>
			<v-col md="6">
				<v-autocomplete v-model="lottoCorrente" :items="listaLotti" :disabled="!promozioneCorrente" label="Lotto" item-text="Descrizione" item-value="Lotto" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-autocomplete>
			</v-col>
		</v-row>
		<v-row>
			<v-col class="text-right">
				<v-btn color="primary" outlined x-large :disabled="!reportGenerabile" :loading="loading" @click="generaReport">cerca</v-btn>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<v-data-table 
					class="reportTable elevation-1"
					item-class="caption"
					:headers="tableHeaders"
					:items="report"
					:search="tableSearch"
					:loading="loading"
					loading-text="Caricamento..."
					item-key="codorganizzato"
				>
					<template v-slot:[`item.totale`]="{ item }">
						<span class="text-no-wrap">{{ item.totale | toCurrency }}</span>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<json-excel :data="report" name="report-contributi.xls" class="" style="cursor: pointer;"><v-icon>mdi-microsoft-excel</v-icon> excel download</json-excel>
			</v-col>
		</v-row>

		<debug-panel label="promozioneCorrente" class="mt-6">
			{{ promozioneCorrente }}
		</debug-panel>
		<debug-panel label="reportRequest" class="mt-6">
			{{ reportRequest }}
		</debug-panel>
		<debug-panel label="report" class="mt-6">
			{{ report }}
		</debug-panel>
	</div>
</template>

<script>
/**********************************************************************************************
**** CARD RIPILOGO FATTURE CAMPAGNE PER AMMINISTRAZIONE ***************************************
***********************************************************************************************
* 
*/
import DebugPanel from '@/components/debug-panel'
import JsonExcel from 'vue-json-excel'
//
export default {
	name: 'ReportContributi',
	components: { DebugPanel, JsonExcel },
	props: {

	},
	data: () => {
		return {
			loading: false,
			campagneAttive: [],
			promozioneCorrente: {},
			listaLotti: [],
			lottoCorrente: '1 ',
			report: [],
			tableSearch: '',
			errors: [],
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		currentUser() { return this.$store.getters['currentUser'] },
		cellularOpenMode() { return this.$store.getters['cellularOpenMode'] },
		isAdmin() { return this.currentUser.IsAdmin || this.currentUser.IsSuperuser},
		minimoAnnoContributi() {
			return this.anniContributi && this.anniContributi.length>0 ? this.anniContributi[0]+ '-01' : '2010-01'
		},
		// true se tutti i parametri necessari per il report sono compilati
		reportGenerabile() { 
			return !!this.promozioneCorrente && !!this.promozioneCorrente.IdPromozione && !!this.lottoCorrente 
		},
		// compone l'oggetto per la richiesta del report
		reportRequest() {
			return {
				IdPromozione: this.promozioneCorrente.IdPromozione,
				NumeroLotto: this.lottoCorrente,
			}
		},
		tableHeaders() {
			var th = []
			th.push({ text: 'Cod.Organizzato' 		, value: 'codorganizzato' 	})
			th.push({ text: 'Organizzato' 			, value: 'organizzato' 		})
			if(this.report && this.report.length>=1) {
				var firstRow = this.report[0]
				console.warn('firstRow', firstRow)	
				for (var field in firstRow) {
					if(field != 'codorganizzato' &&  field != 'organizzato' &&  field != 'totale')
						th.push({ text: field 	, value: field , align: 'end'})				
				}
			}
			th.push({ text: 'Totale' 	, value: 'totale' , align: 'end'})

			return th
		},
	},
	watch:{
	},	
	created: function () {
		this.fetchCampagneAttive()
	},
	methods: {
		// carica le campagne attive
		fetchCampagneAttive() {
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/report/promozioniAttive'}).then(result => {
				this.campagneAttive = result
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		// carica i lotti per la specifica campagna
		fetchLotti() {
			this.loading = true
			this.lottoCorrente = '1 '
			this.$store.dispatch('genericApiPost', {apiUrl: '/report/lottiContributi', value: {Idfile: this.promozioneCorrente.IdPromozione}}).then(result => {
				this.listaLotti = result
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		// genera il report con i parametri indicati
		generaReport() {
			this.report = []
			this.tableExpanded = []
			this.reportDetail = []
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/report/campagne/amministrazione', value: this.reportRequest}).then(result => {
				this.report = result && result.length>0 ? result : []
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		
	},
}
</script>

<style scoped lang="less">
	
</style>