<template>
	<div>
		<v-row class="mt-2">
			<v-col md="12">
				<h1 class="mt-2 mb-6">Card organizzato</h1>
			</v-col>
			<v-col md="6">
				<v-autocomplete v-model="organizzatoCorrente" :items="listaOrganizzati" label="Organizzato" item-text="DescOrganizzato2" item-value="CodOrganizzato" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" @change="fetchData">
				</v-autocomplete>
			</v-col>
			<v-col md="6">
				<v-text-field v-model="tableSearch" append-icon="mdi-magnify" label="Ricerca libera"  hide-details clearable :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-text-field>
			</v-col>
		</v-row>
		<v-row class="mt-5">
			<v-col>
				<v-data-table 
					class="reportTable"
					item-class="caption"
					:headers="tableHeaders"
					:items="reportData"
					:search="tableSearch"
					:loading="loading"
					loading-text="Caricamento..."
				>
					<template v-slot:[`item.attivazione`]="{ item }">
						<span class="text-no-wrap">{{ item.attivazione | reportShortDate }}</span>
					</template>
					<template v-slot:[`item.cessazione`]="{ item }">
						<span class="text-no-wrap red--text">{{ item.cessazione | reportShortDate }}</span>
					</template>
					<template v-slot:[`item.email`]="{ item }">
						<v-btn v-if="item.email" text x-small :href="'mailto:'+item.email" style="margin-left:0;padding-left:0" @click.stop="nop">{{ item.email }}</v-btn>
					</template>
					<template v-slot:[`item.telmobile`]="{ item }">
						<v-btn :disabled="!item.telmobile" text x-small :target="cellularOpenMode ? '_blank' : ''" :href="(cellularOpenMode ? 'https://api.whatsapp.com/send?phone=' : 'tel:') + item.telmobile" style="margin-left:0;padding-left:0" @click.stop="nop">{{ item.telmobile }}</v-btn>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<json-excel :data="reportData" name="report-cardorganizzato.xls" class="" style="cursor: pointer;"><v-icon>mdi-microsoft-excel</v-icon> excel download</json-excel>
			</v-col>
		</v-row>

		<debug-panel label="reportData" class="mt-6">
			{{ reportData }}
		</debug-panel>
	</div>
</template>

<script>
/**********************************************************************************************
**** REPORT CARD ORGANIZZATO ****************************************************************************
***********************************************************************************************
* 
*/
import DebugPanel from '@/components/debug-panel'
import JsonExcel from 'vue-json-excel'
//
export default {
	name: 'ReportCardOrganizzato',
	components: { DebugPanel, JsonExcel },
	props: {

	},
	data: () => {
		return {
			loading: false,
			listaOrganizzati: [], 
			organizzatoCorrente: '',
			tableSearch: '',
			tableHeaders: [
				{ text: 'ID-card' 			, value: 'idcard' 			},
				{ text: 'Stato' 			, value: 'stato' 			},
				{ text: 'Data att.' 		, value: 'attivazione' 		},
				{ text: 'Data cess.' 		, value: 'cessazione' 		},
				{ text: 'Org. cessazione' 	, value: 'orgcessazione' 	},
				{ text: 'Cliente' 			, value: 'nomecliente' 		},
				{ text: 'Provincia' 		, value: 'provcliente' 		},
				{ text: 'Modello' 			, value: 'modellotelaio'	},
				/*
				ID-Card	Stato	Data Att.	Data Cess.	Org. Cessazione	Cliente	Provincia	Modello
				*/			
			],
			reportData: [],
			errors: [],
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		currentUser() { return this.$store.getters['currentUser'] },
		isAdmin() { return this.currentUser.IsAdmin || this.currentUser.IsSuperuser},
		cellularOpenMode() { return this.$store.getters['cellularOpenMode'] },
	},
	created: function () {
		this.fetchOrganizzati()
	},
	methods: {
		// carica gli organizzati 
		fetchOrganizzati() {
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/report/organizzatiAttivi'}).then(result => {
				this.listaOrganizzati = result
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		fetchData() {
			this.reportData = []
			this.loading = true
			let req = {
				CodOrganizzato: this.organizzatoCorrente
			}
			this.$store.dispatch('genericApiPost', {apiUrl: '/report/cardOrganizzato', value: req}).then(result => {
				this.reportData = result
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		nop(){

		}		
	},
}
</script>

<style scoped lang="less">

</style>