<template>
	<div>
		<v-row class="mt-2">
			<v-col md="12">
				<h1 class="mt-2 mb-6">Organizzati aderenti a Škoda Supercard</h1>
			</v-col>
			<v-col md="4">
				<v-autocomplete v-model="provinciaCorrente" clearable label="Provincia" :items="listaProvincie" item-text="NomeProv" item-value="Sigla" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" @change="cambioProvincia"></v-autocomplete>
			</v-col>
			<v-col md="4">
				<v-autocomplete v-model="comuneCorrente" clearable :disabled="!provinciaCorrente" label="Comune" :items="listaComuni" item-text="NomeComune" item-value="NomeComune" :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense" @change="fetchData"></v-autocomplete>
			</v-col>
			<v-col md="4">
				<v-text-field v-model="tableSearch" append-icon="mdi-magnify" label="Ricerca libera" hide-details clearable :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-text-field>
			</v-col>
		</v-row>
		<v-row class="mt-5">
			<v-col>
				<v-data-table 
					class="reportTable"
					item-class="caption"
					:headers="tableHeaders"
					:items="reportData"
					:search="tableSearch"
					:loading="loading"
					loading-text="Caricamento..."
					:footer-props="{ itemsPerPageOptions: [10,25,100,250,500,-1]}"
				>
				</v-data-table>
			</v-col>
		</v-row>
		<v-row>
			<v-col sm="6">
				<json-excel :data="reportData" name="report-organizzatiaderenti.xls" class="" style="cursor: pointer;"><v-icon>mdi-microsoft-excel</v-icon> excel download</json-excel>
			</v-col>
		</v-row>

		<debug-panel label="reportData" class="mt-6">
			{{ reportData }}
		</debug-panel>
	</div>
</template>

<script>
/**********************************************************************************************
**** REPORT ORGANIZZATI ADERENTI ****************************************************************************
***********************************************************************************************
* 
*/
import DebugPanel from '@/components/debug-panel'
import JsonExcel from 'vue-json-excel'
//
export default {
	name: 'ReportOrganizzatiAderenti',
	components: { DebugPanel, JsonExcel },
	props: {

	},
	data: () => {
		return {
			loading: false,
			listaProvincie: [], 
			listaComuni: [], 
			provinciaCorrente: null,
			comuneCorrente: null,
			tableSearch: '',
			tableHeaders: [
				{ text: 'Cod. Org.' 	, value: 'codorganizzato' 	},
				{ text: 'Organizzato'	, value: 'organizzato' 		},
				{ text: 'Indirizzo' 	, value: 'recapito' 			},
				{ text: 'Telefono' 		, value: 'telefono' 				},
				{ text: 'Riferimento' 	, value: 'riferimento' 				},
			],
			reportData: [],
			errors: [],
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		darkTheme() { return this.$vuetify.theme.dark },
		currentUser() { return this.$store.getters['currentUser'] },
		isAdmin() { return this.currentUser.IsAdmin || this.currentUser.IsSuperuser},
		cellularOpenMode() { return this.$store.getters['cellularOpenMode'] },
		totaleImmatricolazioni() {
			var result = 0
			this.reportData.forEach( x => { result += x.qty })
			return result
		}
	},
	created: function () {
		this.caricaProvince()
		this.fetchData()
	},
	methods: {
		// carica tutte le province
		caricaProvince() {
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/report/listaProvince'}).then(result => {
				this.listaProvincie = result
				if(!result)
					this.errors.push({text: 'Errore: non è stato possibile scaricare l\'elenco delle provincie'})
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		//
		cambioProvincia() {
			this.caricaComuni()
			this.fetchData()
		},
		// carica tutti i comuni della provincia selezionata
		caricaComuni() {
			if(this.provinciaCorrente) {
				this.loading = true
				let req = {
					Provincia: this.provinciaCorrente,
				}
				this.$store.dispatch('genericApiPost', {apiUrl: '/report/listaComuni', value: req}).then(result => {
					this.listaComuni = result
				}).catch(error => {
					this.errors.push({text: error, pre: true})
				}).finally(() => {
					this.loading = false
				})
			}
		},
		// carica gli organizzati 
		fetchData() {
			this.reportData = []
			this.loading = true
			let req = {
				Provincia: this.provinciaCorrente,
				Comune: this.comuneCorrente,
			}
			this.$store.dispatch('genericApiPost', {apiUrl: '/report/organizzatiAderenti', value: req}).then(result => {
				this.reportData = result
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		nop(){

		}		
	},
}
</script>

<style scoped lang="less">

</style>