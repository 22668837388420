<template>
	<div>
		<v-row class="mt-2">
			<v-col md="12">
				<h1 class="mt-2 mb-6">Conoscenza cliente</h1>
			</v-col>
			<v-col md="12">
				<v-alert type="warning" text>
					<div class="d-flex justify-space-between">
						<div>Attenzione questo report è molto pesante e può richiedere parecchio tempo per essere generato. I dati vengono aggiornati solo una volta al giorno.</div>
						<div><v-btn color="warning" class="float-right" :disabled="loaded" :loading="loading" @click="fetchData">Carica i dati</v-btn></div>
					</div>
				</v-alert>
			</v-col>
			<v-col md="6"></v-col>
			<v-col md="6">
				<v-text-field v-model="tableSearch" append-icon="mdi-magnify" label="Ricerca libera" hide-details clearable :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-text-field>
			</v-col>
		</v-row>
		<v-row class="mt-5">
			<v-col>
				<v-data-table 
					class="reportTable"
					item-class="caption"
					:headers="tableHeaders"
					:items="reportData"
					:search="tableSearch"
					:loading="loading"
					loading-text="Caricamento..."
				>
					<template v-slot:[`item.attivazione`]="{ item }">
						<span class="text-no-wrap">{{ item.attivazione | reportShortDate }}</span>
					</template>
					<template v-slot:[`item.cessazione`]="{ item }">
						<span class="text-no-wrap red--text">{{ item.cessazione | reportShortDate }}</span>
					</template>
					<template v-slot:[`item.email`]="{ item }">
						<v-btn v-if="item.email" text x-small :href="'mailto:'+item.email" style="margin-left:0;padding-left:0" @click.stop="nop">{{ item.email }}</v-btn>
					</template>
					<template v-slot:[`item.telmobile`]="{ item }">
						<v-btn :disabled="!item.telmobile" text x-small :target="cellularOpenMode ? '_blank' : ''" :href="(cellularOpenMode ? 'https://api.whatsapp.com/send?phone=' : 'tel:') + item.telmobile" style="margin-left:0;padding-left:0" @click.stop="nop">{{ item.telmobile }}</v-btn>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<json-excel :data="reportData" name="report-nuove-auto.xls" class="" style="cursor: pointer;"><v-icon>mdi-microsoft-excel</v-icon> excel download</json-excel>
			</v-col>
		</v-row>

		<debug-panel label="reportData" class="mt-6">
			{{ reportData }}
		</debug-panel>
	</div>
</template>

<script>
/**********************************************************************************************
**** REPORT ACQUISTO NUOVA AUTO VERIFICA ****************************************************************************
***********************************************************************************************
* 
*/
import DebugPanel from '@/components/debug-panel'
import JsonExcel from 'vue-json-excel'
//
export default {
	name: 'ReportNuoveAuto',
	components: { DebugPanel, JsonExcel },
	props: {

	},
	data: () => {
		return {
			loading: false,
			tableSearch: '',
			tableHeaders: [
				{ text: 'ID-card' 			, value: 'Card_ID' 			},
				{ text: 'Modello' 			, value: 'modello' 			},
				{ text: 'Anno immatr.' 		, value: 'Anno_Imm' 		},
				{ text: 'Connuovacar' 		, value: 'connuovacar' 		},
				{ text: 'Attivazione' 			, value: 'attivazione' 			},
				{ text: 'Punti' 				, value: 'punti' 			},
				{ text: 'Sconto' 		, value: 'sconto' 		},
				{ text: 'Org. attivazione' 				, value: 'orgattivazione' 				},
				{ text: 'totpassaggi' 			, value: 'totpassaggi' 			},
				{ text: 'Primo pass.' 		, value: 'primopass' 		},
				{ text: 'Ultimo pass.' 		, value: 'ultimopass' 		},
				{ text: 'Tot. passaggi attivi' 		, value: 'totpassaggiattivi' 		},
				{ text: 'Org. visitati' 		, value: 'orgvisitati' 		},
				{ text: 'Tot. passaggi annullati' 			, value: 'totpassaggiannullati' 			},				
				{ text: 'Garanzia' 			, value: 'garanzia' 			},				
				{ text: 'Carrozzeria' 			, value: 'carrozzeria' 			},				
				{ text: 'm5000' 			, value: 'm5000' 			},				
			],
			reportData: [],
			errors: [],
			loaded: false,
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		currentUser() { return this.$store.getters['currentUser'] },
		isAdmin() { return this.currentUser.IsAdmin || this.currentUser.IsSuperuser},
		cellularOpenMode() { return this.$store.getters['cellularOpenMode'] },
	},
	created: function () {
	},
	methods: {
		fetchData() {
			this.reportData = []
			this.loading = true
			this.$store.dispatch('genericApiPost', {apiUrl: '/report/conoscenzaClienti'}).then(result => {
				this.reportData = result
				this.loaded = true
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		nop(){

		}		
	},
}
</script>

<style scoped lang="less">

</style>