<template>
	<div>
		<v-row class="mt-2">
			<v-col md="12">
				<h1 v-if="isAdmin" class="mt-2 mb-6">Card attive / annullate</h1>
				<h1 v-else class="mt-2 mb-6">Conoscenza cliente</h1>
			</v-col>
			<v-col md="6"></v-col>
			<v-col md="6">
				<v-text-field v-model="tableSearch" append-icon="mdi-magnify" label="Ricerca libera" hide-details clearable :filled="tce.filled" :outlined="tce.outlined" :solo="tce.solo" :dense="tce.dense"></v-text-field>
			</v-col>
		</v-row>
		<v-row class="mt-5">
			<v-col>
				<v-data-table 
					class="reportTable"
					item-class="caption"
					:headers="isAdmin? tableHeadersAdmin : tableHeaders"
					:items="reportData"
					:search="tableSearch"
					:loading="loading"
					loading-text="Caricamento..."
				>
					<template v-slot:[`item.attivazione`]="{ item }">
						<span class="text-no-wrap">{{ item.attivazione | reportShortDate }}</span>
					</template>
					<template v-slot:[`item.cessazione`]="{ item }">
						<span class="text-no-wrap red--text">{{ item.cessazione | reportShortDate }}</span>
					</template>
					<template v-slot:[`item.email`]="{ item }">
						<v-btn v-if="item.email" text x-small :href="'mailto:'+item.email" style="margin-left:0;padding-left:0" @click.stop="nop">{{ item.email }}</v-btn>
					</template>
					<template v-slot:[`item.telmobile`]="{ item }">
						<v-btn :disabled="!item.telmobile" text x-small :target="cellularOpenMode ? '_blank' : ''" :href="(cellularOpenMode ? 'https://api.whatsapp.com/send?phone=' : 'tel:') + item.telmobile" style="margin-left:0;padding-left:0" @click.stop="nop">{{ item.telmobile }}</v-btn>
					</template>
				</v-data-table>
			</v-col>
		</v-row>
		<v-row>
			<v-col>
				<json-excel :data="reportData" name="report-card-attive.xls" class="" style="cursor: pointer;"><v-icon>mdi-microsoft-excel</v-icon> excel download</json-excel>
			</v-col>
		</v-row>

		<debug-panel label="reportData" class="mt-6">
			{{ reportData }}
		</debug-panel>
	</div>
</template>

<script>
/**********************************************************************************************
**** CARD VERIFICA ****************************************************************************
***********************************************************************************************
* 
*/
import DebugPanel from '@/components/debug-panel'
import JsonExcel from 'vue-json-excel'
//
export default {
	name: 'ReportCardAttivePers',
	components: { DebugPanel, JsonExcel },
	props: {

	},
	data: () => {
		return {
			loading: false,
			tableSearch: '',
			tableHeaders: [
				{ text: 'ID-card' 			, value: 'idcard' 			},
				{ text: 'Modello' 			, value: 'modello' 			},
				{ text: 'Data att.' 		, value: 'attivazione' 		},
				{ text: 'Data disatt.' 		, value: 'cessazione' 		},
				{ text: 'Cognome' 			, value: 'cognome' 			},
				{ text: 'Nome' 				, value: 'nome' 			},
				{ text: 'Indirizzo' 		, value: 'indirizzo' 		},
				{ text: 'Cap' 				, value: 'cap' 				},
				{ text: 'Comune' 			, value: 'comune' 			},
				{ text: 'Provincia' 		, value: 'provincia' 		},
				{ text: 'Tel. fisso' 		, value: 'telfisso' 		},
				{ text: 'Tel. mobile' 		, value: 'telmobile' 		},
				{ text: 'Email' 			, value: 'email' 			},				
			],
			tableHeadersAdmin: [
				{ text: 'Cod. Org.' 		, value: 'codorganizzato' 	},
				{ text: 'Organizzato' 		, value: 'organizzato' 		},
				{ text: 'Attive' 			, value: 'TotAttive' 		, align: 'end'},
				{ text: 'Annullate' 		, value: 'TotAnnullate' 	, align: 'end'},
			],
			reportData: [],
			errors: [],
		}
	},
	computed: {
		appSetup() { return window.setup },
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
		setup() { return this.$store.getters['setup'] },
		tce() { return this.$store.getters['tipologiaCampiEdit'] },
		compactVisualization() { return this.$store.getters['compactVisualization'] },	
		currentUser() { return this.$store.getters['currentUser'] },
		isAdmin() { return this.currentUser.IsAdmin || this.currentUser.IsSuperuser},
		cellularOpenMode() { return this.$store.getters['cellularOpenMode'] },
	},
	created: function () {
		this.fetchData()
	},
	methods: {
		fetchData() {
			this.reportData = []
			this.loading = true
			let apiUrl = this.isAdmin ? '/report/cardAttive' : '/report/cardAttivePers'
			this.$store.dispatch('genericApiPost', {apiUrl: apiUrl}).then(result => {
				// filtra via le date nulle in cessazione
				result.forEach(item => {
					if(item.cessazione === '1900-01-01T00:00:00') {
						item.cessazione = ''
					}
				})
				this.reportData = result
			}).catch(error => {
				this.errors.push({text: error, pre: true})
			}).finally(() => {
				this.loading = false
			})
		},
		nop(){

		}		
	},
}
</script>

<style scoped lang="less">

</style>